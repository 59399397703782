<script setup>
import { ref } from "vue";
import { translate } from '@/components/translations.js';

let isOpen = ref(false);
</script>

<template>
  <div>
    <button class="text-white menu-ham-button" @click="isOpen = !isOpen">
      <div class="bg-sky-800 mb-1.5"></div>
      <div class="bg-sky-800 mb-1.5"></div>
      <div class="bg-sky-800 mb-1.5"></div>
    </button>
  </div>

  <div class="z-20" v-if="isOpen">
    <!-- Drawer overlay -->
    <div class="" v-show="isOpen" @click="isOpen = false"></div>
    <!-- Drawer sidebar -->
    <div :class="{ 'translate-x-0': isOpen, 'translate-x-full': !isOpen }" class="fixed top-0 z-50 side-menu h-full shadow-xl transition-transform duration-300 ease-in-out flex justify-end text-white drawer">
      <!-- Contenido del drawer -->
      <nav class="mr-5 flex flex-col items-end">
        <div class="mt-5">
          <button class="text-white p-2" @click="isOpen = !isOpen">
            <i class="pi pi-times text-xl"></i>
          </button>
        </div>

        <a target="_blank" href="https://wa.me/18493576458" class="block sm:hidden drawer-make-appointment">{{translate('make_your_appointment')}}</a>
        <router-link to="/" class="font-bold p-2.5 uppercase text-white md:text-xl">{{translate('home')}}</router-link>
        <router-link to="/Our-Story" class="p-2.5 font-light text-white md:text-xl">{{translate('who_are_we')}}</router-link>
        <!--<router-link to="/" class="p-2.5 font-light">Our Doctor</router-link>-->
        <router-link to="/FAQs" class="p-2.5 font-light text-white md:text-xl">FAQs</router-link>
        <!--<router-link to="/" class="p-2.5 font-light">Billing</router-link>-->
        <router-link to="/WellVisitInfo" class="p-2.5 font-light text-white md:text-xl">{{translate('well_visit_info')}}</router-link>
        <router-link to="/Donations" class="p-2.5 font-light text-white md:text-xl">{{translate('donations')}}</router-link>
        <router-link to="/Accessibility" class="p-2.5 font-light text-white md:text-xl">{{translate('accessibility')}}</router-link>
        <!--<router-link to="/" class="p-2.5 font-light">Vaccine Schedule</router-link>-->
        <!--<router-link to="/" class="p-2.5 font-light">Child and Adolescent Support</router-link>-->
        <router-link to="/HealthGuide" class="p-2.5 font-light text-white md:text-xl">A-Z Health Guide</router-link>
        <router-link to="/MedicationDosage" class="p-2.5 font-light text-white md:text-xl">Medication Dosage</router-link>
        <router-link to="/Rights" class="p-2.5 font-light text-white md:text-xl">{{translate('patient_rights')}}</router-link>
        <router-link to="/Responsibilities" class="p-2.5 font-light text-white md:text-xl">{{translate('patient_responsibilities')}}</router-link>
        <!--<router-link to="/" class="p-2.5 font-light">Breastfeeding Support</router-link>-->
        <!--<router-link to="/" class="p-2.5 font-light">Newsletter</router-link>-->
      </nav>
    </div>
  </div>
</template>
