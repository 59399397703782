import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import OurStory from "../view/OurStory.vue";
import Accessibility from "../view/Accessibility.vue";
import FAQs from "../view/FAQs.vue";
import WellVisitInfo from "../view/WellVisitInfo.vue";
import Donations from "../view/Donations.vue";
import MedicationDosage from "../view/MedicationDosage.vue";
import HealthGuide from "../view/HealthGuide.vue";
import Rights from "../view/Rights.vue";
import Responsibilities from "../view/Responsibilities.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Our-Story",
    name: "Our-Story",
    component: OurStory,
  },
  {
    path: "/Accessibility",
    name: "accesibility",
    component: Accessibility,
  },
  {
    path: "/FAQs",
    name: "FAQs",
    component: FAQs,
  },
  {
    path: "/WellVisitInfo",
    name: "WellVisitInfo",
    component: WellVisitInfo,
  },
  {
    path: "/Donations",
    name: "Donations",
    component: Donations,
  },
  {
    path: "/MedicationDosage",
    name: "MedicationDosage",
    component: MedicationDosage,
  },
  {
    path: "/HealthGuide",
    name: "HealthGuide",
    component: HealthGuide,
  },
  {
    path: "/Rights",
    name: "Rights",
    component: Rights,
  },
  {
    path: "/Responsibilities",
    name: "Responsibilities",
    component: Responsibilities,
  }  

  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
