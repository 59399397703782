<script setup>
  import { ref } from 'vue';
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import { translate } from '@/components/translations.js';

  const collapsedItems = ref({});

  function toggleCollapse(id) {
    var content = document.getElementById(id);
    if (content.classList.contains("hidden")) {
      content.classList.remove("hidden");
      collapsedItems[id] = false;

    } else {
      content.classList.add("hidden");
      collapsedItems[id] = true;
    }

  }

  function isCollapsed(id) {
    return (collapsedItems.hasOwnProperty(id) ? collapsedItems[id] : false);
  }

  
</script>

<template>
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    <Header />
    <span class="w-100 text-center text-3xl font-bold custom-black d-block section-title">{{translate('faqs_extended')}}</span>

    <div class="donations-border-blue-container donations-border-blue-container-mobile w-100">

      <div class="flex flex-col pb-10">
        <div @click="toggleCollapse('collapseContent0')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq1_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent0'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent0') }"></i>
          </div>
          <div id="collapseContent0" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq1_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent1')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq2_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent1'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent1') }"></i>
          </div>
          <div id="collapseContent1" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq2_answer')}}
          </div>
        </div>
        <!--
        <div @click="toggleCollapse('collapseContent2')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq3_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent2'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent2') }"></i>
          </div>
          <div id="collapseContent2" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq1_answer')}}
          </div>
        </div>-->
        <div @click="toggleCollapse('collapseContent3')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq4_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent3'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent3') }"></i>
          </div>
          <div id="collapseContent3" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq4_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent4')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq5_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent4'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent4') }"></i>
          </div>
          <div id="collapseContent4" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq5_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent5')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg ">
              <div class="custom-list-disc pl-0"><li>{{translate('faq6_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent5'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent5') }"></i>
          </div>
          <div id="collapseContent5" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq6_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent6')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq7_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent6'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent6') }"></i>
          </div>
          <div id="collapseContent6" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq7_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent7')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq8_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent7'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent7') }"></i>
          </div>
          <div id="collapseContent7" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq8_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent8')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq9_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent8'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent8') }"></i>
          </div>
          <div id="collapseContent8" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq9_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent9')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq10_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent9'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent9') }"></i>
          </div>
          <div id="collapseContent9" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq10_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent10')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq11_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent10'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent10') }"></i>
          </div>
          <div id="collapseContent10" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq11_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent11')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq12_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent11'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent11') }"></i>
          </div>
          <div id="collapseContent11" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq12_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent12')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq13_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent12'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent12') }"></i>
          </div>
          <div id="collapseContent12" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq13_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent13')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq14_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent13'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent13') }"></i>
          </div>
          <div id="collapseContent13" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq14_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent14')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq15_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent14'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent14') }"></i>
          </div>
          <div id="collapseContent14" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq15_answer')}}
          </div>
        </div>
        <div @click="toggleCollapse('collapseContent15')" class="border-b-1 border-black  px-5 md:px-0 pt-5">
          <div class="flex flex-row justify-between items-center">
            <button class="py-2  flex justify-start text-left pl-0 custom-black focus:outline-none font-medium text-lg">
              <div class="custom-list-disc pl-0"><li>{{translate('faq16_question')}}</li></div>
            </button>
            <i :class="{'pi pi-angle-right custom-black': !isCollapsed('collapseContent15'), 'pi pi-angle-down custom-black': isCollapsed('collapseContent15') }"></i>
          </div>
          <div id="collapseContent15" class="mt-3 hidden custom-black pl-0 pb-2 font-light text-lg text-justify">
            {{translate('faq16_answer')}}
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
