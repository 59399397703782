import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import { currentLanguage, changeLanguage } from './language.js';

loadFonts();
const app = createApp(App);

app.provide('currentLanguage', currentLanguage);
app.provide('changeLanguage', changeLanguage);

app.use(router).use(vuetify).mount("#app");

window.addEventListener('scroll', function() {
    var element = document.querySelector('.sticky-header');
    var logoElement = document.querySelector('.home-logo');
    var logo = document.querySelector('.home-logo > div > img');

    var scrollPosition = window.scrollY;

    if (scrollPosition > 100) {
      element.classList.add('scrolled');
      if (logoElement) {
        logoElement.style.top = '0px';
      }
      if (logo) {
        logo.style.width = '275px';
      }
    } else {
      element.classList.remove('scrolled');
      if (logoElement) {
        logoElement.style.top = '70px';
      }
      if (logo) {
        logo.style.width = '390px';
      }
    }
  });