<script setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { register } from 'swiper/element/bundle';
import "swiper/swiper-bundle.css";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { translate } from '@/components/translations.js';
import logoOther from "../assets/otherLogo.png";

function contentCollapse(id) {
  var allContentPanels = document.querySelectorAll(".collapsible");
  var allContentVerticalLines = document.querySelectorAll(".well-visit-vertical-line");
  var allCircles = document.querySelectorAll(".well-visit-circle");
  
  allContentPanels.forEach((panel) => {
    if (panel.id !== `content${id}`) {
      panel.classList.add("hidden");
    }
  });

  allContentVerticalLines.forEach((line) => {
    if (line.id !== `vertical_line_${id}`) {
      line.classList.add("hidden");
    }
  });

  allCircles.forEach((circle) => {
    circle.classList.remove("active");
  });
                                                                        
  var contentToToggle = document.getElementById(`content${id}`);
  var verticalLineToggle = document.getElementById('vertical_line_'+id);
  var circleToToggle = document.getElementById(`circle${id}`);

  contentToToggle.classList.remove("hidden");
  verticalLineToggle.classList.remove("hidden");
  circleToToggle.classList.add("active");

}
</script>

<template>
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    <Header />
    <div class="flex flex-row">
      <div class="pb-10 pl-10 flex flex-row w-[100%]">
        <span class="w-100 text-center text-3xl font-bold custom-black d-block section-title">{{translate('well_visit_info')}}</span>
      </div>
    </div>
    <swiper :slidesPerView="3" :spaceBetween="0" :breakpoints="{768: {slidesPerView: 5,},}"  :centered-slides="false" :navigation="true" :observer="true" :observeParents="true" :parallax="true" slidesPerView="4">
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col active" id="circle1" @click="contentCollapse('1')">
            <button class="text-sm font-bold uppercase">{{translate('newborn')}}</button>
            <button class="text-sm font-bold uppercase">{{translate('visit')}}</button>
          </div>
          <div class="w-0.5 bg-customobscurePurple well-visit-vertical-line mx-auto" id="vertical_line_1"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle2" @click="contentCollapse('2')">
            <button class="text-4xl font-extrabold">1</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('month')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_2"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle3" @click="contentCollapse('3')">
            <button class="text-4xl font-extrabold">2</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_3"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle4" @click="contentCollapse('4')">
            <button class="text-4xl font-extrabold">3</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_4"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle5" @click="contentCollapse('5')">
            <button class="text-4xl font-extrabold">4</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_5"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle6" @click="contentCollapse('6')">
            <button class="text-4xl font-extrabold">6</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_6"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle7" @click="contentCollapse('7')">
            <button class="text-4xl font-extrabold">9</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_7"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle8" @click="contentCollapse('8')">
            <button class="text-4xl font-extrabold">12</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_8"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle9" @click="contentCollapse('9')">
            <button class="text-4xl font-extrabold">15</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_9"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle10" @click="contentCollapse('10')">
            <button class="text-4xl font-extrabold">18</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('months')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_10"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle11" @click="contentCollapse('11')">
            <button class="text-4xl font-extrabold">2</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_11"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle12" @click="contentCollapse('12')">
            <button class="text-4xl font-extrabold">2 1/2</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_12"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle13" @click="contentCollapse('13')">
            <button class="text-4xl font-extrabold">3</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_13"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle14" @click="contentCollapse('14')">
            <button class="text-4xl font-extrabold">4</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_14"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle15" @click="contentCollapse('15')">
            <button class="text-4xl font-extrabold">5</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_15"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle16" @click="contentCollapse('16')">
            <button class="text-4xl font-extrabold">6</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_16"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle17" @click="contentCollapse('17')">
            <button class="text-4xl font-extrabold">7</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_17"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle18" @click="contentCollapse('18')">
            <button class="text-4xl font-extrabold">8</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_18"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle19" @click="contentCollapse('19')">
            <button class="text-4xl font-extrabold">9</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_19"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle20" @click="contentCollapse('20')">
            <button class="text-4xl font-extrabold">10</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_20"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle21" @click="contentCollapse('21')">
            <button class="text-4xl font-extrabold">11</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_21"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="relative w-100">
          <div class="rounded-full mx-auto well-visit-circle w-24 h-24 flex justify-center items-center flex-col" id="circle22" @click="contentCollapse('22')">
            <button class="text-4xl font-extrabold">12+</button>
            <button class="text-lg font-light leading-5 lowercase">{{translate('years')}}</button>
          </div>
          <div class="well-visit-vertical-line w-0.5 bg-customobscurePurple mx-auto hidden" id="vertical_line_22"></div>
        </div>
      </swiper-slide>
    </swiper>
    
    <div id="content1" class="collapsible p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <p class="my-5">{{translate('congratulations_on_the_birth_of_your_baby')}}</p>
      <p class="mb-5"> 
        {{translate('at_your_baby_newborn_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
        <li>{{translate('newborn_meassures')}}</li>
        <li>{{translate('newborn_hearing_test')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('newborn_feeding')}}</p>
      <p>{{translate('newborn_sleep')}}</p>
      <p class="mt-5">{{translate('swaddling_the_baby')}}</p>
      <p>{{translate('newborn_burping')}}</p>
      <p class="mt-5">{{translate('newborn_hiccups')}}</p>
      <p>{{translate('newborn_leaving_the_nest')}}</p>
      <p class="mt-5">{{translate('newborn_helpful_msg')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content2" class="p-5 collapsible hidden  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="mb-5 font-bold uppercase">{{translate('one_month_visit')}}</h1>
      <p class="mb-5">
        {{translate('at_your_one_month_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
        <li>{{translate('we_will_take_your_meassurements')}}</li>
    </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('your_baby_will_start_looking_at_your_face')}}</p>
      <p class=""><span class="font-bold">{{translate('feeding')}}:</span> {{translate('at_this_point_you_can_breast_feed_while_talking')}}</p>
      <p class=""><span class="font-bold">{{translate('the_dream')}}:</span> {{translate('the_dream_newborn_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content3" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-2 uppercase">{{translate('the_two_months_visit')}}</h1>
      <p class="mb-4">{{translate('at_your_two_months_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
        <li>{{translate('newborn_two_months_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-2">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('two_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('feeding')}}:</span> {{translate('two_months_feeding_info')}}</p>
      <p class=""><span class="font-bold">{{translate('the_dream')}}:</span> {{translate('two_months_dream_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content4" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-2 uppercase">{{translate('the_three_months_visit')}}</h1>
      <p class="mb-4">{{translate('at_the_three_months_visit_we_will')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
        <li>{{translate('we_will_confirm_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('three_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('the_dream')}}:</span> {{translate('three_months_dream_info')}}</p>
      <p class=""><span class="font-bold">{{translate('dentition')}}:</span> {{translate('dentition_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content5" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('the_four_months_visit')}}</h1>
      <p class="font-bold">{{translate('we_will_do_this')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
        <li>{{translate('we_will_take_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('three_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('the_feeding')}}:</span> {{translate('three_month_feeding_info')}}</p>
      <p class=""><span class="font-bold">{{translate('solid_food')}}:</span> {{translate('three_month_solid_food_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content6" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('the_six_months_visit')}}</h1>
      <p class="mb-4">{{translate('at_your_six_months_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('we_will_verify_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('six_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('intro_to_solid_food')}}:</span> {{translate('six_months_intro_to_solid_food_info')}}</p>
      <p class=""><span class="font-bold">{{translate('separation_anxiety')}}:</span> {{translate('six_months_separation_anxiety_info')}}</p>
      <p class=""><span class="font-bold">{{translate('sleep_advices')}}:</span> {{translate('six_months_sleep_advices_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content7" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('the_nine_months_visit')}}</h1>
      <p class="mb-4">{{translate('at_your_nine_months_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('nine_months_we_will_verify_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('nine_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('solid_food')}}:</span> {{translate('nine_months_solid_food_info')}}</p>
      <p class=""><span class="font-bold">{{translate('nine_months_separation_anxiety')}}:</span> {{translate('nine_months_separation_anxiety_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content8" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('one_year_visit')}}</h1>
      <p class="mb-4">{{translate('happy_birthday_baby')}}</p>
      <p class="mb-4">{{translate('one_year_visit_is_a_great_moment')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('one_year_meassurements')}}</li>
          <li>{{translate('one_year_lead_detection')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('one_year_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('the_feeding')}}:</span> {{translate('one_year_feeding_info_1')}}</p>
      <p>{{translate('one_year_feeding_info_2')}}</p>
      <p class=""><span class="font-bold">{{translate('frustration_management')}}:</span> {{translate('one_year_frustration_management_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content9" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('fifteen_months_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('fifteen_months_visit_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('fifteen_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('eat')}}:</span> {{translate('fifteen_months_eating_info')}}</p>
      <p class=""><span class="font-bold">{{translate('setting_limits')}}:</span> {{translate('fifteen_months_setting_limits')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content10" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('eighteen_months_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit_baby_stops_being_a_baby')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('eighteen_months_visit_meassurements')}}</li>
          <li>{{translate('we_will_carry_standard_autism')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('eighteen_months_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('feeding')}}:</span> {{translate('eighteen_months_feeding_info')}}</p>
      <p class=""><span class="font-bold">{{translate('setting_limits')}}:</span> {{translate('eighteen_months_setting_limits')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content11" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('two_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('eighteen_months_visit_meassurements')}}</li>
          <li>{{translate('we_will_carry_standard_autism')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('two_years_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('whining')}}:</span> {{translate('two_years_whining_info')}}</p>
      <p class=""><span class="font-bold">{{translate('sphincter_control')}}:</span> {{translate('two_years_sphincter_control_info')}}</p>
      <p class=""><span class="font-bold">{{translate('the_child_bed')}}:</span> {{translate('two_years_child_bed_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content12" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('two_and_a_half_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}: 
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('two_and_a_half_years_visit_meassurements')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('two_and_a_half_years_guide_intro')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('two_and_a_half_years_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('motor_skills')}}:</span> {{translate('two_and_a_half_years_motor_skills_info')}}</p>
      <p class=""><span class="font-bold">{{translate('social_life')}}:</span> {{translate('two_and_a_half_years_social_life_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('two_and_a_half_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content13" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('three_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_the_three_years_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('three_years_visit_meassurements')}}</li>
          <li>{{translate('three_years_visit_tests')}}</li>
          <li>{{translate('three_years_visit_blood_pressure')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('three_years_guide_intro')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('three_years_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('physical_activity')}}:</span> {{translate('three_years_physical_activity_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('three_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content14" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('four_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('four_years_visit_meassurements')}}</li>
          <li>{{translate('four_years_visit_tests')}}</li>
          <li>{{translate('four_years_visit_blood_pressure')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('four_years_visit_guide_intro')}}:</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('four_years_development_info')}}</p>
      <p>{{translate('four_years_development_info_2')}}</p>
      <p class=""><span class="font-bold">{{translate('emotional_intelligence')}}:</span> {{translate('four_years_emotional_intelligence_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('four_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content15" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('five_years_visit')}}</h1>
      <p class="mb-4">{{translate('at_the_five_years_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('five_years_visit_meassurements')}}</li>
          <li>{{translate('five_years_visit_blood_pressure')}}</li>
          <li>{{translate('five_years_visit_tests')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('five_years_visit_guide_intro')}}:</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('five_years_development_info')}}</p>
      <p class=""><span class="font-bold">{{translate('health_and_security')}}:</span> {{translate('five_years_health_and_security_info')}}</p>
      <p>{{translate('five_years_health_and_security_info_2')}}</p>
      <p>{{translate('five_years_health_and_security_info_3')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content16" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('six_years_visit')}}</h1>
      <p class="mb-4">{{translate('at_the_six_years_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('six_years_visit_meassurements')}}</li>
          <li>{{translate('six_years_visit_blood_pressure')}}</li>
          <li>{{translate('six_years_visit_tests')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_development')}}:</span> {{translate('six_years_development_info')}}</p>
      <p>{{translate('six_years_development_info_2')}}</p>
      <p>{{translate('six_years_development_info_3')}}</p>
      <p class=""><span class="font-bold">{{translate('health_and_security')}}:</span> {{translate('six_years_health_and_security_info')}}</p>
      <p>{{translate('six_years_health_and_security_info_2')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content17" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('seven_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('seven_years_visit_meassurements')}}</li>
          <li>{{translate('seven_years_visit_blood_pressure')}}</li>
          <li>{{translate('seven_years_visit_tests')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_school')}}:</span> {{translate('seven_years_school_info')}}</p>
      <p>{{translate('seven_years_school_info_2')}}</p>
      <p class=""><span class="font-bold">{{translate('health')}}:</span> {{translate('seven_years_health_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('seven_years_security_info')}}</p>
      <p class=""><span class="font-bold">{{translate('outdoor')}}:</span> {{translate('seven_years_outdoor_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content18" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('eight_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('seven_years_visit_meassurements')}}</li>
          <li>{{translate('seven_years_visit_blood_pressure')}}</li>
          <li>{{translate('seven_years_visit_tests')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_school')}}:</span> {{translate('eight_years_school_info')}}</p>
      <p class=""><span class="font-bold">{{translate('health')}}:</span> {{translate('eight_years_health_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('eight_years_security_info')}}</p>
      <p>{{translate('eight_years_security_info_2')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content19" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('nine_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('nine_years_visit_meassurements')}}</li>
          <li>{{translate('nine_years_visit_tests')}}</li>
          <li>{{translate('nine_years_visit_blood_pressure')}}</li>
          <li>{{translate('nine_years_cholesterol_test')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_school')}}:</span> {{translate('nine_years_school_info')}}</p>
      <p class=""><span class="font-bold">{{translate('health')}}:</span> {{translate('nine_years_health_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('nine_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content20" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('ten_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('nine_years_visit_meassurements')}}</li>
          <li>{{translate('nine_years_visit_tests')}}</li>
          <li>{{translate('nine_years_visit_blood_pressure')}}</li>
          <li>{{translate('ten_years_cholesterol_test')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_school')}}:</span> {{translate('ten_years_school_info')}}</p>
      <p class=""><span class="font-bold">{{translate('nutrition')}}:</span> {{translate('ten_years_nutrition_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('ten_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content21" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('eleven_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visit')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('eleven_years_visit_meassurements')}}</li>
          <li>{{translate('nine_years_visit_tests')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p class=""><span class="font-bold">{{translate('the_talk')}}:</span> {{translate('eleven_years_talk_info')}}</p>
      <p class=""><span class="font-bold">{{translate('behavior_and_well_being')}}:</span> {{translate('eleven_years_behavior_info')}}</p>
      <p class=""><span class="font-bold">{{translate('security')}}:</span> {{translate('eleven_years_security_info')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <div id="content22" class="collapsible hidden p-5  mb-10 xl:w-100 well-visit-content border-2 border-customobscurePurple rounded-xl font-light">
      <h1 class="font-bold mb-4 uppercase">{{translate('twelve_years_visit')}}</h1>
      <p class="mb-4">{{translate('in_this_visits')}}:
      </p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('twelve_years_visit_meassurements')}}</li>
          <li>{{translate('twelve_years_visit_tests')}}</li>
          <li>{{translate('twelve_years_visit_evaluations')}}</li>
          <li>{{translate('twelve_years_visit_cholesterol_test')}}</li>
      </ul>
      <p class="font-bold mb-1">{{translate('guide')}}</p>
      <p>{{translate('twelve_years_guide_intro')}}</p>
      <p>{{translate('twelve_years_guide_intro_2')}}</p>
      <p>{{translate('twelve_years_guide_intro_3')}}</p>
      <p class=""><span class="font-bold">{{translate('resources')}}:</span> {{translate('twelve_years_resources_info')}}</p>
      <ul class="list-disc ml-3 mb-3">
          <li>{{translate('twelve_years_resources_info_list_1')}}</li>
          <li>{{translate('twelve_years_resources_info_list_2')}}</li>
          <li>{{translate('twelve_years_resources_info_list_3')}}</li>
          <li>{{translate('twelve_years_resources_info_list_4')}}</li>
      </ul>
      <p class="">{{translate('visit_our_resources_page')}}</p>
      <div>
        <img :src="logoOther" class="mx-auto" />
      </div>
    </div>
    <Footer />
  </div>
</template>
