<script setup>
import { ref } from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue"

import { translate } from '@/components/translations.js';

import "primeicons/primeicons.css";

  const collapsedItems = ref({});

  function toggleCollapse(id) {
    var content = document.getElementById(id);
    if (content.classList.contains("hidden")) {
      content.classList.remove("hidden");
      collapsedItems[id] = false;

    } else {
      content.classList.add("hidden");
      collapsedItems[id] = true;
    }

  }

  function isCollapsed(id) {
    return (collapsedItems.hasOwnProperty(id) ? collapsedItems[id] : false);
  }

</script>

<template>
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    <Header />
      <div class="container">
        <span class="w-100 text-center text-3xl font-bold custom-black d-block section-title">{{translate('accessibility')}}</span>
        
        <div class="flex flex-wrap accessibility-container mb-10">
          <div class="w-full">
            <div class="accessibility-info w-100">
              <p class="custom-black text-xl font-light pb-3">
                {{translate('insurance_info')}}
              </p>
            </div>
          </div>
        </div>
        
        <div class="donations-border-blue-container">
          <div class="donations-border-blue-container-title custom-blue font-bold text-2xl text-center mb-2.5"><i>{{translate('price_list')}}</i></div>
          <div class="donations-border-blue-container-item-price-mobile donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_1')}}</div>
          <div class="donations-border-blue-container-item">
            <div class="donations-border-blue-container-item-price donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_1')}}</div>
            <div class="donations-border-blue-container-item-description donations-border-blue-container-item-description-aux">{{translate('price_list_1_desc')}}</div>
          </div>
          <div class="donations-border-blue-container-item-price-mobile donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_3')}}</div>
          <div class="donations-border-blue-container-item">
            <div class="donations-border-blue-container-item-price donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_3')}}</div>
            <div class="donations-border-blue-container-item-description donations-border-blue-container-item-description-aux">{{translate('price_list_3_desc')}}</div>
          </div>
          <div class="donations-border-blue-container-item-price-mobile donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_4')}}</div>
          <div class="donations-border-blue-container-item">
            <div class="donations-border-blue-container-item-price donations-border-blue-container-item-price-aux custom-bg-orange">{{translate('price_list_4')}}</div>
            <div class="donations-border-blue-container-item-description donations-border-blue-container-item-description-aux">{{translate('price_list_4_desc')}}</div>
          </div>
            
        </div>
       
        <!-- <div class="donations-border-blue-container w-100 border-0 mt-10">
          <ul class="custom-list-disc ml-3">
            <li class="text-lg text-justify mb-0">{{translate('accessibility_faq_question_1')}}</li>
            <div class="mb-4">{{translate('accessibility_faq_answer_1')}}</div>
            <li class="text-lg text-justify mb-0">{{translate('accessibility_faq_question_2')}}</li>
            <div class="mb-4">{{translate('accessibility_faq_answer_2')}}</div>
            <li class="text-lg text-justify mb-0">{{translate('accessibility_faq_question_3')}}</li>
            <div class="mb-4">{{translate('accessibility_faq_answer_3')}}</div>
          </ul>    
        </div> -->



      </div>
    <Footer />
  </div>
</template>
