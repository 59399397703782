<script setup>
import { ref, onMounted} from 'vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue"

function getTribecaMedicationDosage() {

  const headers = {
  };

  fetch('https://www.amis-bi.com/dev/tribeca.cfm', {
    method: 'GET',
    headers: headers,
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.text();
  })
  .then(html => {
    const container = document.getElementById('medication_dosage_container');
    if (container) {
      container.innerHTML = html;
      const tpHeader = document.getElementById('tp-header');
      const tpFooter = document.getElementById('tp-footer');
      const newBasicsBook = document.querySelector('.the-new-basics-book');

      if (tpHeader) tpHeader.style.display = "none";
      if (tpFooter) tpFooter.style.display = "none";
      if (newBasicsBook) newBasicsBook.style.display = "none";
    } else {
      console.error('Container element not found');
    }
  })
  .catch(error => console.error('Error fetching data:', error));
}


onMounted(() => {
  getTribecaMedicationDosage();
});

</script>

<template>
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    <Header />
      <div class="container" id="medication_dosage_container"></div>
    <Footer />
  </div>
</template>
