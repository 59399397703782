<script setup>
  import { ref, onMounted, computed, watchEffect} from 'vue';
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  import NewBasicsTitle from "../assets/the new basics title.png";
  import healthDefinitionsEnglish from '../assets/HealthDefinitionsEng.json';
  import healthDefinitionsSpanish from '../assets/HealthDefinitionsEsp.json';
  import NewBasicsImage from '../assets/the new basics.png';
  import ProvidedByImage from '../assets/otherLogo.png';
  import ArrowLeft from '../assets/arrow-left.svg';
  import ArrowRight from '../assets/arrow-right.svg';
  import { translate } from '@/components/translations.js';

  const healthDefinitionsEng = ref(null);
  const healthDefinitionsSpa = ref(null);
  var currentLanguage = ref('');
  var currentDefinitions = ref([]);
  var filteredDefinitions = ref([]);
  var isModalOpen = ref(false);
  var isMobileView = ref(false); 
  var searchedDefinitions = ref([]); 
  var selectedDefinition = ref({}); 
  var search = ref(false);
  var searchString = ref('');

  watchEffect(() => {
    isMobileView.value = window.innerWidth < 640;
  });

  const getHealthDefinitions = () => {
    healthDefinitionsEng.value = healthDefinitionsEnglish;
    healthDefinitionsSpa.value = healthDefinitionsSpanish;
  };

  const organizeDefinitionsAlphabetically = (definitions) => {
    const organized = {};
    definitions.forEach((definition) => {
      const firstLetter = definition.name.charAt(0).toUpperCase();
      if (!organized[firstLetter]) {
        organized[firstLetter] = [];
      }
      organized[firstLetter].push(definition);
    });
    return organized;
  };

  const currentHealthDefinitions = computed(() => {
    return currentLanguage.value == 'en' ? healthDefinitionsEng.value : healthDefinitionsSpa.value;
  });

  const alphabeticallyOrganizedDefinitions = computed(() => {
    return organizeDefinitionsAlphabetically(filteredDefinitions.value);
  });

  const changeLanguage = (lang) => {
    currentLanguage.value = lang;
    currentDefinitions.value = Object.values(currentHealthDefinitions.value);
    filterDefinitions();
  };

  async function toggleContent(fDefinition) {

    search.value = false;
    var definitionElements = document.querySelectorAll('.health-guide-definition');
    definitionElements.forEach(e => {
      e.classList.remove('active');
    });

    if (document.getElementById(fDefinition.replace(/ /g,'').trim().toUpperCase()) != null) {
      document.getElementById(fDefinition.replace(/ /g,'').trim().toUpperCase()).classList.add('active');
    }
    selectedDefinition.value = currentHealthDefinitions.value[fDefinition];
    
    if (isMobileView.value) {
      await openModal();
    }



  }

  async function openModal(){
    isModalOpen.value = true;
  }

  function filterDefinitions(){

    var currentFilterValue = searchString.value;
    filteredDefinitions.value = currentDefinitions.value.filter(definition => {
      return definition.name.toUpperCase().includes(currentFilterValue.toUpperCase());
    });

  }

  function matchKeywordAppearances() {
    search.value = true;
    var currentFilterValue = searchString.value;
    var filteredDefinitionsByKeyword = currentDefinitions.value.filter(definition => {
      return definition.name.toUpperCase().includes(currentFilterValue.toUpperCase()) || definition.content.toUpperCase().includes(currentFilterValue.toUpperCase());
    });

    searchedDefinitions.value = extractSearchedContent(currentFilterValue, filteredDefinitionsByKeyword);
  }

  function extractSearchedContent(value, definitions){

    const searchValue = value.toUpperCase();

     return definitions.map(definition => {
        const content = definition.content.toUpperCase();
        const startIndex = content.indexOf(searchValue);
        if (startIndex !== -1) {
            const leftIndex = Math.max(0, startIndex - 150);
            const rightIndex = Math.min(content.length, startIndex + searchValue.length + 150);
            const extractedContent = definition.content.substring(leftIndex, rightIndex);
            const highlightedContent = extractedContent.replace(new RegExp(searchValue, 'gi'), match => `<span class="highlight">${match}</span>`);
            return {
                ...definition,
                content: highlightedContent
            };
        } else {
            return definition;
        }
    });

  }

  function closeContent(){
    selectedDefinition.value = ref({});
    var definitionElements = document.querySelectorAll('.health-guide-definition');
    definitionElements.forEach(e => {
      e.classList.remove('active');
    });
    search.value = true;
  }


  onMounted(() => {
    currentLanguage.value = document.querySelector('.language-button.active').dataset.lang;
    getHealthDefinitions();
    currentDefinitions.value = Object.values(currentHealthDefinitions.value);
    filteredDefinitions.value = currentDefinitions.value;

    const languageButtons = document.querySelectorAll('.language-button');
    languageButtons.forEach(button => {
      button.addEventListener('click', () => {
        changeLanguage(button.dataset.lang);
      });
    });

  });

</script>

<template>

  <div v-if="isModalOpen" class="modal-overlay">
    <div class="modal-content">
      <button class="modal-close-button" @click="isModalOpen = false;">X</button>
      <div class="modal-body">
          <div id="modal_health_guide_content">
            <div class="font-bold text-base">{{selectedDefinition.name}}</div>
            <div class="">{{selectedDefinition.content}}</div>
          </div>
      </div>
    </div>
  </div>
                                                  
  <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">    
    <Header />
      <div class="flex flex-wrap">
          <div class="w-full health-guide-title-container mb-12">
            <img :src="NewBasicsTitle" class="w-[40%] mx-auto">
            <span class="block text-center font-medium text-xs sm:text-sm">{{translate('by')}}: Michel Cohen, M.D.</span>
          </div>
          <div class="w-full sm:w-1/3">
            <div class="health-guide-filter-definitions-container">
              <input type="text" name="health_guide_definition" id="health_guide_definition" class="health-guide-search block w-full rounded-md border-0 py-1.5 pl-3 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6 w-100" :placeholder="translate('search_for_a_word')+'...'" @keyup="filterDefinitions();matchKeywordAppearances()" v-model="searchString">
              <div class="health-guide-filter-definitions-scrollable-container">
                <template v-for="(definitions, letter) in alphabeticallyOrganizedDefinitions" :key="letter">
                  <div class="mb-4">
                    <div class="uppercase text-lg text-gray-500 bg-white inline-block w-[40px] h-[40px] flex items-center justify-center rounded-3xl font-black mb-2">{{ letter }}</div>
                    <div v-for="definition in definitions" class="health-guide-definition" :id="definition.name.replace(/ /g,'').trim().toUpperCase()" @click="toggleContent(definition.name)">
                      <span class="uppercase text-sm">{{ definition.name }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="hidden sm:block w-full sm:w-2/3">
              <div id="health_guide_content" class="px-10 pb-10 text-justify text-sm">
                  <img v-if="(!search || searchString == '') && Object.keys(selectedDefinition).length == 0" :src="NewBasicsImage" class="w-full">
                  <div v-else-if="search && searchString != ''">
                    <template v-for="def in searchedDefinitions">
                      <div class="searched-definition-container mb-8" @click="toggleContent(def.name)">
                        <div class="searched-definition-container-title custom-black font-medium text-lg">{{def.name}} <img :src="ArrowRight" class="w-[25px] d-inline-block align-text-bottom float-right"></div>
                        <div class="searched-definition-container-content custom-black" v-html="def.content"></div>
                      </div>
                    </template>
                  </div>
                  <div v-else-if="Object.keys(selectedDefinition).length > 0">
                      <button v-if="searchString != ''" type="button" class="d-block ml-auto" @click="closeContent()"><img :src="ArrowLeft" class="w-[25px]"></button>
                      <div class="font-bold text-base">{{selectedDefinition.name}}</div>
                      <div class="">{{selectedDefinition.content}}</div>
                  </div>
              </div>
              <div class="content-provided-by">
                  <img :src="ProvidedByImage" class="mx-auto">
              </div>
          </div>
      </div>
    <Footer />
  </div>
</template>
