<script setup>
import { ref } from "vue";
import childImage from "../assets/foto8.png";
import docImage from "../assets/foto9.png";
import exteriorImage from "../assets/06 Exterior.jpg";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { translate } from '@/components/translations.js';

const exterior = ref(exteriorImage);

</script>

<template>
    <div class="container lg:max-w-screen-md xl:max-w-screen-xl mx-auto">
    
        <Header />
    
        <div class="p-5 md:px-0 md:pb-0 md:pt-0">
    
            <span class="w-100 text-center text-3xl font-bold custom-black d-block section-title who-are-we">{{translate('who_are_we')}}</span>
    
            <div class="entry-content rel">
    
                <div class="story-container-bg">
                    
                    <div class="story-container rel">
    
                        <div class="who-are-we-building-mobile-img round-img round-img-abs w-full"><img decoding="async" :src="exterior" alt="Our Story" data-hasqtip="1" aria-describedby="qtip-1"></div>

                        <div class="inner-content who-are-we-building-info">
                            <p class="mb-2">{{translate('who_we_are_info_1')}} </p>
                            <div class="para2"><p class="mb-2">{{translate('who_we_are_info_2')}}</p></div>

                        </div>
    
                        <div class="who-are-we-building-img round-img round-img-abs"><img decoding="async" :src="exterior" alt="Our Story" data-hasqtip="1" aria-describedby="qtip-1"></div>
    
                    </div>
    
                    <div class="inner-content-bottom">
    
                        <div class="founder-msg">
    
                            <div class="founder-pic"><img decoding="async" src="https://tribecapediatrics.com/wp-content/uploads/2023/05/dr-michel-cohen.jpg" alt="Dr Michel Cohen" data-hasqtip="2"></div>
    
                            <div class="text-dark">
    
                                <h4 class="sub-title" tabindex="53">Dr. Michel Cohen</h4>
    
                                <p class="custom-black text-justify pb-4 text-sm font-normal text-justify">{{translate('our_story_info_1')}}</p>
    
                                <p class="custom-black text-justify pb-4 text-sm font-normal text-justify">{{translate('our_story_info_2')}}</p>
        
                                <p class="custom-black text-justify pb-4 text-sm font-normal text-justify">{{translate('our_story_info_3')}}</p>
    
                            </div>
    
                        </div>
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    
        <Footer />
    
    </div>
</template>
