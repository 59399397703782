import { inject } from 'vue';

export const translate = (key) => {
  
  const currentLanguage = inject('currentLanguage');

  const translations = {
    en: require('../translations/en.json'),
    esp: require('../translations/es.json')
  };
  
  if (translations[currentLanguage.value] && translations[currentLanguage.value][key]) {
    return translations[currentLanguage.value][key];
  } else {
    return translations['en'][key];
  }
};