<script setup>
import logoBotton from "../assets/logo_botton.png";
import { ref } from "vue";
import { translate } from './translations.js';
import footerLogo from "../assets/logo_vue_white.png";

const footer_logo = ref(footerLogo);
</script>

<template>
  
  <div class="wave-bottom w-full text-coolGray-900 header-wave mt-20">
    <svg viewBox="0 0 1440 116" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1440 64.5909H1090.08C833.336 64.5909 580.229 -7.62939e-06 360 -7.62939e-06C139.771 -7.62939e-06 0 64.5909 0 64.5909V116H1440V64.5909Z" fill="#DD7C33" stroke="#DD7C33" stroke-width="2" stroke-dasharray="1567" stroke-dashoffset="116"></path>
    </svg>                  
  </div>
  <div class="flex flex-wrap custom-bg-orange" style="margin-top: -1px;">    
    <div class="flex flex-wrap p-5 md:px-20 md:py-5 w-100">
      <div class="footer-column w-full sm:w-1/2 md:w-1/3 text-white">
        <ul class="footer-list">
          <li><router-link to="/">{{ translate('home') }}</router-link></li>
          <li><router-link to="/Our-Story">{{ translate('who_are_we') }}</router-link></li>
          <li><router-link to="/FAQs">{{ translate('faqs') }}</router-link></li>
          <li><router-link to="/WellVisitInfo">{{ translate('well_visit_info') }} </router-link></li>
          <li><router-link to="/Donations">{{translate('donations')}}</router-link></li>
        </ul>
      </div>
      <div class="footer-column w-full sm:w-1/2 md:w-1/3 text-white">
        <ul class="footer-list">
          <li><router-link to="/HealthGuide">{{translate('health_guide')}}</router-link></li>
          <li><router-link to="/MedicationDosage">{{translate('medication_dosage')}}</router-link></li>
          <li><router-link to="/Accessibility">{{ translate('accessibility') }} </router-link></li>
          <li><router-link to="/Rights">{{translate('patient_rights')}}</router-link></li>
          <li><router-link to="/Responsibilities">{{translate('patient_responsibilities')}}</router-link></li>
        </ul>
      </div>
      <div class="w-full sm:w-1/2 md:w-1/3 text-white">
        <ul class="footer-list">
          <li class="font-bold">{{translate('phone')}}: <a target="_blank" class="text-white" href="https://wa.me/18493576458">(849) 357-6458</a></li>
          <li class="font-bold">{{translate('email')}}: carinopediatrics@gmail.com</li>
          <li class="footer-social-network-container"><i class="pi pi-instagram text-xl"> </i><i class="pi pi-facebook text-xl"> </i> <span class="align-text-bottom">@carinopediatrics </span></li>
        </ul>
        <div class="footer-logo">
          <router-link to="/">
            <img :src="footer_logo" :alt="footer_logo" class="object-cover"/>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
